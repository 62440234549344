<template>
  <div v-if="liquidacion">
    <!-- {{width}} px -->
    <div class="card">
      <h2 class="card-title">
        Ver liquidación:
        <span class="text-primary">#{{ liquidacion.numero }}</span>
      </h2>
      <div class="cols2">
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="liquidacion.periodo"
          label="Período"
          id="periodo"
          placeholder="periodo"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          v-model="liquidacion.tipo_liquidacion"
          label="Tipo"
          id="Tipo"
          placeholder="Tipo"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          v-model="liquidacion.user.nombre"
          label="Creador"
          id="Creador"
          placeholder="Creador"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          :value="fecha_liquidacion"
          label="Fecha creación"
          id="Fechacreación"
          placeholder="Fecha creación"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          :value="recibos_liquidacion"
          label="Recibos liquidados"
          id="Recibosliquidados"
          placeholder="Recibos liquidados"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-secondary"
          :value="neto_liquidacion"
          label="Neto total"
          id="Netototal"
          placeholder="Neto total"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-success"
          :value="recibos_firmados"
          label="Recibos firmados"
          id="Recibosliquidados"
          placeholder="Recibos firmados"
        ></pd-input>
        <pd-input
          class="my-0"
          readonly
          classInput="form-control readonly text-bold text-danger"
          :value="recibos_en_disconformidad"
          label="Recibos con observaciones"
          id="Recibosliquidados"
          placeholder="Recibos con observaciones"
        ></pd-input>
      </div>
      <div class="d-flex right" v-if="!(recibos_firmados+recibos_en_disconformidad)" @click="eliminarLiquidacion" >
        <button class="btn btn-error">Eliminar liquidacion</button>
      </div>
    </div>
    <TableRecibosAdmin
      :recibos="liquidacion.recibos"
      :readonly="false"
      @update="updateRecibos"
    ></TableRecibosAdmin>
  </div>
</template>
<script>
import TableRecibosAdmin from "@/modules/recibos/views/TableRecibosAdmin";
import moment from "moment";
import { LiquidacionesServices } from "../services/LiquidacionesServices";
import Swal from 'sweetalert2';
export default {
  name: "VerLiquidacion",
  components: {
    TableRecibosAdmin,
  },
  data() {
    return {
      liquidacion: null,
    };
  },
  computed: {
    fecha_liquidacion: function () {
      return moment(this.liquidacion.created_at, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
    },
    recibos_liquidacion: function () {
      return this.liquidacion.recibos.length;
    },
    recibos_firmados: function(){
      return this.liquidacion.recibos.filter((recibo) => {return (recibo.estado == 'impreso')}).length
    },
    recibos_en_disconformidad: function(){
      return this.liquidacion.recibos.filter((recibo) => {return (recibo.estado == 'impreso_con_observaciones')}).length
    },
    neto_liquidacion: function () {
      return LiquidacionesServices.numberFormat(this.liquidacion.recibos.reduce((a, b) => a + (b['neto'] || 0), 0));
    },
  },
  mounted() {
    LiquidacionesServices.api.find(this.$route.params.id).then((response) => {
      this.liquidacion = response.liquidacion;
    });
  },
  methods: {
    updateRecibos() {
      LiquidacionesServices.api.find(this.$route.params.id).then((response) => {
        this.liquidacion = response.liquidacion;
      });
    },
    eliminarLiquidacion() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar la liquidación?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          LiquidacionesServices.api.delete(this.liquidacion).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarLiquidaciones" });
          });
        }
      });
    },
  },
};
</script>