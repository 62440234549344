<template>
  <div class="card">
    <h3 class="card-title">Listado de recibos</h3>
    <div class="half my-1">
      <div class="cols2">
        <pd-input
          v-model="filter"
          label="Buscar:"
          id="filter"
          placeholder="Buscar por legajo, nombre o cuil"
        ></pd-input>
        <pd-input
          v-model="estadoFilter"
          label="Estado:"
          id="estadoFilter"
          placeholder="estados"
          :select="true"
        >
          <template slot="options">
            <option
              v-for="opt in optionsEstado"
              :key="opt.value"
              :value="opt.value"
            >
              {{ opt.text }}
            </option>
          </template>
        </pd-input>
      </div>
    </div>
    <table-list
      :showFilter="false"
      :data="recibosFiltrados"
      :header="headers"
      :body="body"
      @rowClicked="previewRecibo"
    ></table-list>
    <ShowRecibos
      v-if="reciboSelected"
      :recibo="reciboSelected"
      @close="close"
      :readonly="true"
    ></ShowRecibos>
  </div>
</template>
<script>
import { LiquidacionesServices } from "@/modules/liquidaciones/services/LiquidacionesServices";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import TableList from "@/components/elements/TableList";
import moment from "moment";
import ShowRecibos from "@/modules/recibos/views/ShowRecibos";
export default {
  name: "TableRecibosAdmin",
  components: {
    TableList,
    ShowRecibos,
  },
  data() {
    return {
      filter: "",
      estadoFilter: null,
      optionsEstado: [
        { value: null, text: "Todos los estados" },
        { value: "pendiente", text: "Pendientes" },
        { value: "impreso", text: "Impresos" },
        { value: "impreso_con_observaciones", text: "Con observaciones" },
      ],
      reciboSelected: null,
    };
  },
  props: {
    recibos: null,
  },
  computed: {
    headers() {
      const header = [
        { value: "Legajo", sortable: true },
        { value: "Persona", sortable: true },
        { value: "Neto", sortable: true },
        { value: "Estado", sortable: true },
        { value: "Fecha impreso", sortable: true },
        { value: "Observaciones", sortable: true },
      ];
      return header;
    },
    body() {
      const body = [
        {
          class: "w-100",
          data: [{ value: "numero_legajo", class: "text-primary", label: "#" }],
        },
        {
          class: "important",
          data: [
            { value: "nombre", class: "text-500" },
            {
              value: "cuil",
              class: "text-small text-secondary",
              mutator: (cuil) => {
                return PersonasService.formatCuil(cuil);
              },
            },
          ],
        },
        {
          class: "w-100",
          data: [
            {
              value: "neto",
              label: "Total",
              class: "text-success",
              mutator: (value) => {
                return LiquidacionesServices.numberFormat(value);
              },
            },
          ],
        },
        {
          class: "w-100",
          data: [
            {
              value: "estado",
              label: "Estado",
              class: "nowrap",
              mutator: (estado) => {
                let span = document.createElement("span");
                span.classList.add("badge");
                if (estado == "pendiente") {
                  span.innerText = "Pendiente";
                  span.classList.add("warning");
                }
                if (estado == "impreso") {
                  span.innerText = "Impreso";
                  span.classList.add("success");
                }
                if (estado == "impreso_con_observaciones") {
                  span.innerText = "Con observaciones";
                  span.classList.add("error");
                }
                return span.outerHTML;
              },
            },
          ],
        },
        {
          class: "w-100",
          data: [
            {
              value: "fecha_recibido",
              label: "Fecha impreso",
              mutator: (fecha) => {
                if (fecha) {
                  return moment(fecha, "YYYY-MM-DD").format("DD/MM/YYYY");
                } else {
                  return "-";
                }
              },
            },
          ],
        },
        {
          class: "w-100",
          data: [
            {
              value: "observacion_empleado",
              label: "Observaciones",
              mutator: (obs) => {
                if (!obs) {
                  return "-";
                }
                return obs;
              },
            },
          ],
        },
      ];
      return body;
    },
    recibosFiltrados: function () {
      let recibos = [...this.recibos];
      return recibos.filter((recibo) => {
        let finded = false;
        if (
          (recibo.cuil
            .toString()
            .toLowerCase()
            .includes(this.filter.toLowerCase()) ||
            recibo.nombre
              .toString()
              .toLowerCase()
              .includes(this.filter.toLowerCase()) ||
            recibo.numero_legajo
              .toString()
              .toLowerCase()
              .includes(this.filter.toLowerCase())) &&
          (!this.estadoFilter || recibo.estado == this.estadoFilter)
        ) {
          finded = true;
        }
        return finded;
      });
    },
  },
  methods: {
    previewRecibo(recibo) {
      this.reciboSelected = recibo;
    },
    close() {
      this.reciboSelected = null;
      this.$emit("update");
    },
  },
};
</script>